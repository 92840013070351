import React, { useEffect, useReducer } from 'react';
import { Loader, Panel } from 'rsuite';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface Props {
  header: string;
  errorMessage: string;
  fetchXml: () => Promise<string>;
}

interface State {
  xml: string | undefined;
  isLoading: boolean;
  error: any | undefined;
}

const initialState: State = {
  xml: undefined,
  isLoading: false,
  error: undefined,
};

type LoadAction = { type: 'load' };
type LoadSuccessAction = { type: 'loadSuccess', payload: string };
type LoadFailureAction = { type: 'loadFailure', payload: any };
type Action = LoadAction | LoadSuccessAction | LoadFailureAction;

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state, isLoading: true, xml: undefined, error: undefined,
      };
    case 'loadSuccess':
      return {
        ...state, isLoading: false, xml: action.payload,
      };
    case 'loadFailure':
      return {
        ...state, isLoading: false, error: action.payload,
      };
    default:
      return state;
  }
};

function XMLPreview({ header, errorMessage, fetchXml }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'load' });
    fetchXml()
      .then((data) => {
        const trimmed = data.split('\n').slice(0, 100).join('\n');
        dispatch({ type: 'loadSuccess', payload: trimmed });
      })
      .catch((err) => {
        dispatch({ type: 'loadFailure', payload: err });
      });
  }, [header, fetchXml]);

  return (
    <Panel header={header} collapsible bordered>
      {state.isLoading && <Loader vertical />}
      {state.error
        ? <p>{errorMessage}</p>
        : (
          <SyntaxHighlighter
            language="xml"
            style={vs}
            customStyle={{ fontSize: 12, maxHeight: 400 }}
            showLineNumbers
          >
            {state.xml ?? ''}
          </SyntaxHighlighter>
        )}
    </Panel>
  );
}

export default XMLPreview;
