import React from 'react';
import {
  Button, Modal, Notification, useToaster,
} from 'rsuite';
import Remind from '@rsuite/icons/legacy/Remind';
import { deleteMorph, Morph } from '../services/MorphsService';

interface Props {
  open: boolean;
  morph: Morph;
  onClose: () => void;
  onSuccess?: () => void;
}

function DeleteMorphAlert({
  open, morph, onClose, onSuccess,
}: Props) {
  const toaster = useToaster();

  const onCancel = () => {
    onClose();
  };

  const onDelete = () => {
    deleteMorph(morph.id)
      .then(() => {
        toaster.push(
          <Notification type="success" header="Success" closable>
            Item was successfully deleted.
          </Notification>,
        );
        onSuccess?.();
      })
      .catch(error => {
        toaster.push(
          <Notification type="error" header="Error" closable>
            {error.message}
          </Notification>,
        );
      })
      .finally(() => onClose());
  };

  return (
    <Modal open={open} onClose={onClose} role="alertdialog" size="xs">
      <Modal.Header>
        <Remind style={{ color: '#ffb300', fontSize: 16 }} title="text" />
        <b style={{ padding: 10 }}>Deletion</b>
      </Modal.Header>
      <Modal.Body>
        Do you really want delete item with id:
        {' '}
        {morph.id}
        {' '}
        ?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDelete} color="red" appearance="primary">
          Delete
        </Button>
        <Button onClick={onCancel} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DeleteMorphAlert.defaultProps = {
  onSuccess: undefined,
};

export default DeleteMorphAlert;
