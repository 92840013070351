import React, { ReactNode } from 'react';
import { Navbar, Stack } from 'rsuite';

interface Props {
  title: string;
  children?: ReactNode;
}

function PageHeader({ title, children }: Props) {
  return (
    <Navbar appearance="inverse">
      <Stack style={{ padding: 20 }} justifyContent="space-between">
        <h4>{title}</h4>
        <Stack justifyContent="flex-end">
          {children}
        </Stack>
      </Stack>
    </Navbar>
  );
}

PageHeader.defaultProps = {
  children: undefined,
};

export default PageHeader;
