import axios from 'axios';
import { baseUrl } from '../Configuration';

const morphsUrl = `${baseUrl}/api/morphs`;

export interface Morph {
  id: number;
  xmlUrl: string;
  xsltTransformation: string;
}

export async function getMorphs() {
  const { data } = await axios.get<Morph[]>(
    morphsUrl,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return data;
}

export async function getMorph(id: number) {
  const { data } = await axios.get<Morph>(
    `${morphsUrl}/${id}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return data;
}

export async function postMorph(morph: Morph) {
  const { headers } = await axios.post<void>(
    morphsUrl,
    morph,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return headers.location.split('/').pop() as number;
}

export async function patchMorph(morph: Morph) {
  const { status } = await axios.patch<number>(
    `${morphsUrl}/${morph.id}`,
    morph,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return status;
}

export async function saveMorph(morph: Morph) {
  if (morph.id > 0) {
    return patchMorph(morph);
  }
  return postMorph(morph);
}

export async function deleteMorph(id: number) {
  const { status } = await axios.delete<number>(
    `${morphsUrl}/${id}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return status;
}

export async function getPreviewDOM(url: string) {
  const { data } = await axios.get<string>(
    `${morphsUrl}/preview/dom`,
    {
      params: {
        url,
      },
      headers: {
        Accept: 'application/xml',
      },
    },
  );
  return data;
}

export async function getPreviewTransformation(url: string, xslt: string) {
  const { data } = await axios.post<string>(
    `${morphsUrl}/preview/transformation`,
    xslt,
    {
      params: {
        url,
      },
      headers: {
        Accept: 'application/xml',
        'Content-Type': 'application/xml',
      },
    },
  );
  return data;
}
