import React, { useEffect, useState } from 'react';
import {
  Panel, IconButton, Form,
  Input, InputProps, ButtonToolbar,
  Button, Stack, Notification, useToaster,
  Placeholder, Schema,
} from 'rsuite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Close } from '@rsuite/icons';
import PageContainer from '../components/PageContainer';
import {
  getMorph, Morph, saveMorph,
} from '../services/MorphsService';
import XMLTransformationPreview from '../components/XMLPreview/XMLTransformationPreview';
import XMLDOMPreview from '../components/XMLPreview/XMLDOMPreview';

function Header() {
  return (
    <Link to="/">
      <IconButton icon={<Close />} appereance="subtle" circle />
    </Link>
  );
}

const Textarea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props, ref) => <Input {...props} as="textarea" ref={ref} />,
);

const initialMorph: Morph = {
  id: 0,
  xmlUrl: '',
  xsltTransformation: '',
};

const model = Schema.Model({
  xmlUrl: Schema.Types.StringType().isRequired(),
  xsltTransformation: Schema.Types.StringType().isRequired(),
});

function AddMorphPage() {
  const [morph, setMorph] = useState<Morph>({ ...initialMorph });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const toaster = useToaster();
  const navigate = useNavigate();
  const { id } = useParams();

  const isEditing = !!id;

  function checkValidation(data: Morph) {
    const check = model.check(data);
    const keys = model.getKeys();
    return keys.every((key) => !(check as any)[key].hasError);
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getMorph(Number(id))
        .then((data) => {
          setMorph(data);
          setIsValid(checkValidation(data));
        })
        .catch(error => {
          toaster.push(
            <Notification type="error" header="Error" closable>
              {error.message}
            </Notification>,
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  const onSave = () => {
    saveMorph(morph)
      .then(() => {
        navigate('/');
        toaster.push(
          <Notification type="success" header="Success" closable>
            {isEditing ? 'Item was successfully edited.' : 'New item was successfully added.'}
          </Notification>,
        );
      })
      .catch(error => {
        toaster.push(
          <Notification type="error" header="Error" closable>
            {error.message}
          </Notification>,
        );
      });
  };

  return (
    <PageContainer
      title={isEditing ? 'Edit morph' : 'Add new morph'}
      header={<Header />}
    >
      <Panel bordered>
        {
          isLoading
            ? <Placeholder.Paragraph rows={10} active />
            : (
              <Form
                formValue={morph}
                onChange={(changed) => {
                  setMorph(changed as Morph);
                  setIsValid(checkValidation(changed as Morph));
                }}
                model={model}
                fluid
              >
                <Form.Group controlId="xml-url-1">
                  <Form.ControlLabel>XML url</Form.ControlLabel>
                  <Stack direction="column" alignItems="stretch" spacing={20}>
                    <Form.Control name="xmlUrl" />
                    <XMLDOMPreview url={morph.xmlUrl} />
                  </Stack>
                </Form.Group>
                <Form.Group controlId="xslt-transformation-2">
                  <Stack direction="column" alignItems="stretch" spacing={20}>
                    <Form.ControlLabel>XSLT transformation</Form.ControlLabel>
                    <Form.Control style={{ minHeight: 400 }} name="xsltTransformation" accepter={Textarea} />
                    <XMLTransformationPreview url={morph.xmlUrl} xslt={morph.xsltTransformation} />
                  </Stack>
                </Form.Group>
                <Form.Group>
                  <Stack justifyContent="flex-end">
                    <ButtonToolbar>
                      <Button appearance="primary" onClick={onSave} disabled={!isValid}>Save</Button>
                      <Link to="/">
                        <Button appearance="default">Cancel</Button>
                      </Link>
                    </ButtonToolbar>
                  </Stack>
                </Form.Group>
              </Form>
            )
        }
      </Panel>
    </PageContainer>
  );
}

export default AddMorphPage;
