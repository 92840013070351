import React, { ReactNode } from 'react';
import {
  Button, Popover, Whisper,
} from 'rsuite';
import { PositionChildProps } from 'rsuite/Picker';

type OnClose = (delay?: number) => NodeJS.Timeout | void;

interface OnCloseInterface {
  onClose: OnClose;
}

interface SpeakerProps extends PositionChildProps, Pick<React.HTMLAttributes<HTMLElement>, 'id' | 'onMouseEnter' | 'onMouseLeave'>, OnCloseInterface {
  children: ReactNode;
}

function Speaker({
  children, onClose, left, top, className,
}: SpeakerProps, ref: React.RefCallback<HTMLElement>) {
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full onClick={() => onClose()}>
      {children}
    </Popover>
  );
}

interface Props {
  title: string;
  children: ReactNode;
}

function DropdownOverlay({ children, title }: Props) {
  return (
    <Whisper
      placement="bottomStart"
      trigger="click"
      speaker={(props, ref) => Speaker({ ...props, children }, ref)}
    >
      <Button>{title}</Button>
    </Whisper>
  );
}

export default DropdownOverlay;
