import React from 'react';
import { Panel, IconButton } from 'rsuite';
import { Link } from 'react-router-dom';
import { Plus } from '@rsuite/icons';
import MorphsTable from '../components/MorphsTable';
import PageContainer from '../components/PageContainer';

function Header() {
  return (
    <Link to="/morphs/add">
      <IconButton icon={<Plus />}>
        Add XML
      </IconButton>
    </Link>
  );
}

function MorphsPage() {
  return (
    <PageContainer
      title="XML-Morph"
      header={<Header />}
    >
      <Panel header={<h4>Items</h4>} bordered>
        <MorphsTable />
      </Panel>
    </PageContainer>
  );
}

export default MorphsPage;
