import React from 'react';
import XMLPreview from './XMLPreview';
import { getPreviewDOM } from '../../services/MorphsService';

interface Props {
  url: string;
}

function XMLDOMPreview({ url }: Props) {
  const fetchXml = () => getPreviewDOM(url);

  return (
    <XMLPreview
      header="DOM preview"
      errorMessage="Invalid XML url."
      fetchXml={fetchXml}
    />
  );
}

export default XMLDOMPreview;
