import React from 'react';
import XMLPreview from './XMLPreview';
import { getPreviewTransformation } from '../../services/MorphsService';

interface Props {
  url: string;
  xslt: string;
}

function XMLTransformationPreview({ url, xslt }: Props) {
  const fetchXml = () => getPreviewTransformation(url, xslt);

  return (
    <XMLPreview
      header="Transformation preview"
      errorMessage="Invalid XSLT transformation."
      fetchXml={fetchXml}
    />
  );
}

export default XMLTransformationPreview;
