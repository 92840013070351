import React, { useEffect, useState } from 'react';
import {
  Button, ButtonToolbar, Dropdown, Notification, Table, useToaster,
} from 'rsuite';
import { Link } from 'react-router-dom';
import { Copy, FileDownload } from '@rsuite/icons';
import { getMorphs, Morph } from '../services/MorphsService';
import { baseUrl } from '../Configuration';
import DeleteMorphAlert from './DeleteMorphAlert';
import DropdownOverlay from './DropdownOverlay';

const { Column, HeaderCell, Cell } = Table;

interface TransformationsProps {
  morph: Morph
}

function Transformations({ morph }: TransformationsProps) {
  const toaster = useToaster();

  const transformUrl = () => `${baseUrl}/api/morphs/${morph.id}/transform`;

  const onCopy = () => {
    navigator
      .clipboard
      .writeText(transformUrl())
      .then(() => {
        toaster.push(
          <Notification type="info" header="Information" closable>
            Url successfully copied to your clipboard.
          </Notification>,
        );
      })
      .catch((error) => {
        toaster.push(
          <Notification type="error" header="Error" closable>
            {error.message}
          </Notification>,
        );
      });
  };

  return (
    <DropdownOverlay title="Select">
      <Dropdown.Menu>
        <a href={transformUrl()} target="_blank" download type="application/xml" rel="noreferrer">
          <Dropdown.Item
            icon={<FileDownload />}
          >
            Download transformation
          </Dropdown.Item>
        </a>
        <Dropdown.Item
          icon={<Copy />}
          onClick={onCopy}
        >
          Copy transformation url
        </Dropdown.Item>
      </Dropdown.Menu>
    </DropdownOverlay>
  );
}

interface ActionsProps {
  morph: Morph,
  onDelete: () => void;
}

function Actions({ morph, onDelete }: ActionsProps) {
  return (
    <ButtonToolbar>
      <Link to={`/morphs/${morph.id}/edit`}>
        <Button
          size="sm"
          color="blue"
          appearance="primary"
        >
          Edit
        </Button>
      </Link>
      <Button
        size="sm"
        color="red"
        appearance="primary"
        onClick={() => onDelete()}
      >
        Delete
      </Button>
    </ButtonToolbar>
  );
}

function MorphsTable() {
  const [morphs, setMorphs] = useState<Morph[]>([]);
  const [deletingMorph, setDeletingMorph] = useState<Morph | undefined>(undefined);
  const [isDeleteMorphAlertOpen, setIsDeleteMorphAlertOpen] = useState<boolean>(false);

  const toaster = useToaster();

  const fetchMorphs = () => {
    getMorphs()
      .then((data) => setMorphs(data))
      .catch(error => {
        toaster.push(
          <Notification type="error" header="Error" closable>
            {error.message}
          </Notification>,
        );
      });
  };

  useEffect(() => {
    fetchMorphs();
  }, []);

  return (
    <>
      <Table height={600} fillHeight rowHeight={60} data={morphs} showHeader>
        <Column flexGrow={5} verticalAlign="middle">
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column flexGrow={40} verticalAlign="middle" fullText>
          <HeaderCell>XML Url</HeaderCell>
          <Cell dataKey="xmlUrl" />
        </Column>
        <Column flexGrow={40} verticalAlign="middle" fullText>
          <HeaderCell>XSLT Transformation</HeaderCell>
          <Cell dataKey="xsltTransformation" />
        </Column>
        <Column flexGrow={5} verticalAlign="middle">
          <HeaderCell>Transformation</HeaderCell>
          <Cell>
            {morph => (<Transformations morph={morph as Morph} />)}
          </Cell>
        </Column>
        <Column flexGrow={15} verticalAlign="middle">
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {morph => (
              <Actions
                morph={morph as Morph}
                onDelete={
                  () => {
                    setDeletingMorph(morph as Morph);
                    setIsDeleteMorphAlertOpen(true);
                  }
              }
              />
            )}
          </Cell>
        </Column>
      </Table>
      {
        deletingMorph
          && (
          <DeleteMorphAlert
            morph={deletingMorph}
            open={isDeleteMorphAlertOpen}
            onClose={() => {
              setDeletingMorph(undefined);
              setIsDeleteMorphAlertOpen(false);
            }}
            onSuccess={() => fetchMorphs()}
          />
          )
      }
    </>
  );
}

export default MorphsTable;
