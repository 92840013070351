import React from 'react';
import './styles/styles.css';
import { Route, Routes } from 'react-router';
import MorphsPage from './pages/MorphsPage';
import AddMorphPage from './pages/AddMorphPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MorphsPage />} />
      <Route path="/morphs/add" element={<AddMorphPage />} />
      <Route path="/morphs/:id/edit" element={<AddMorphPage />} />
    </Routes>
  );
}

export default App;
