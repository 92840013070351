import React, { ReactNode } from 'react';
import {
  Container, Content, FlexboxGrid, Header,
} from 'rsuite';
import PageHeader from './PageHeader';

interface Props {
  title: string;
  children: ReactNode;
  header?: ReactNode;
}

function PageContainer({ title, header, children }: Props) {
  return (
    <Container>
      <Header>
        <PageHeader title={title}>{header}</PageHeader>
      </Header>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item style={{ padding: 20 }} colspan={24}>
            {children}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
}

PageContainer.defaultProps = {
  header: undefined,
};

export default PageContainer;
